import { useEffect, useState } from "react"
import { text } from "stream/consumers"

export const PopUpNatification = (props: {
    text: string,
    setPopUpItemAdd: any,

}) => {
    const [openFlag, setOpenFlag] = useState(false)

    useEffect(()=>{
        setOpenFlag(true)

        const timer = setTimeout(() => {
            setOpenFlag(false)
            props.setPopUpItemAdd({
                text: "",
                open: false
            })
        }, 5000);
        return () => clearTimeout(timer);
    },[])

    return (
        <div className={`add-to__natification ${openFlag ? 'active':''}`}>
            {props.text}
        </div>
    )
}