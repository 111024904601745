import { useState } from "react"

export const OpenerItem = (props: any) => {
    const [open, setOpen] = useState(false)
    
    return(
        <div className={`questions-item ${open ? "active":""}`}>
            <div className="pls" onClick={()=>{setOpen(!open)}}></div>
            {props.title}
            {props.desc}
        </div>
    )
}