import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link, useSearchParams } from "react-router-dom"
import { PopupInfo } from "../../component/PopupInfo"
import { Helmet } from "react-helmet"
import eye from "../../images/eye.svg"
import eyeClose from "../../images/eye-closed.svg"

export const LoginPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupText, setOpenPopupText] = useState("false")
    const [form, setForm] = useState({ 
        email: "",
        password: ""
    })
    const [searchParams, setSearchParams] = useSearchParams();

    const [showPassword, setShowPassword] = useState(false)
    
    useEffect(()=>{
        if(auth.user.userToken != ""){
            window.location.replace('/account/');
        }
        
        if(searchParams.get("conf") == "true"){
            setOpenPopupText(`Ваш аккаунт успешно подтвержден`)
            setOpenPopup(true)
        }

    },[])

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        auth.user.Login(form.email, form.password).then(function(response){
            if(response.error == false){
                setForm({
                    email: "",
                    password: ""
                })
                if(searchParams.get("cart") == "true")
                    window.location.replace('/order/')
                else
                    window.location.replace('/account/')
            }else{
                setOpenPopupText(response.msg)
                setOpenPopup(true)
            }
        }).catch(function(error){
            alert("error")
        })
    }
    
    return(
        <>
            <Helmet>
                <title>Авторизация - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="form-login">
                <div className="content">
                    <div className="form">
                        <h2 className="block-title">Войти</h2> 
                        <form onSubmit={handleSubmit}>
                            <label>
                                <input className="base-input" type="email" maxLength={255} placeholder="Email" required name="email" value={form.email} onChange={changeHandler}  />
                            </label>
                            <label className="show-password">
                                <input 
                                    className="base-input" 
                                    type={showPassword ? 'text' : 'password'}
                                    maxLength={40} 
                                    placeholder="Пароль" 
                                    required 
                                    name="password" 
                                    value={form.password} 
                                    onChange={changeHandler}  
                                />
                                <img 
                                    onClick={() => {setShowPassword(!showPassword)}}
                                    src={showPassword ? eye : eyeClose} 
                                />
                            </label>
                            <div>
                                <button className="orange-button">Войти</button>
                                <span className="dop-span">
                                    <Link to={"/password/"}>Забыли пароль</Link> / <Link to={"/register/"}>Регистрация</Link>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {
                openPopup ? (
                    <PopupInfo text={openPopupText} close={setOpenPopup} />
                ):""
            }
        </>
    )
}