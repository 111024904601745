import axios from "axios"
export const hostImg = "https://api.als-info.ru/"
export const host = "https://api.als-info.ru/user/"

export interface CourseInterface{
    pk: string
    title: string
    image: string
    imageDetail: string
    miniDesc: string
    cost: number
    finalCost: number
    duration: string
    about?: string
}

export interface ReviewsInterface{
    pk: number
    fio: string
    course: {
        pk: number,
        title: string
    }
    createDate: string
    review: string
}

export interface OrderItemInterface{
    pk: number
    title: string
    cost: number
    course: {
        pk: number
        title: string
        image: string
        count_modules: number
        descPercent: number
    }
}

export interface ModuleInterface{
    pk: number
    title: string
    cost: number
    miniDesc: string
    finalCost: number
    sort: number
    lesson_module: [{
        pk: number
    }]
}




export class Review implements ReviewsInterface{
    pk: number
    fio: string
    course: {
        pk: number,
        title: string
    }
    createDate: string
    review: string

    constructor(item: ReviewsInterface){
        this.pk = item.pk
        this.fio = item.fio
        this.course = item.course
        this.createDate = item.createDate
        this.review = item.review
    }
}
export class ReviewsList{
    list: ReviewsInterface[]
    pageSize: number
    constructor(pageSize: number){
        this.list = []
        this.pageSize = pageSize
    }

    async getData(page: number, search: number = 0, count: number = 100){
        const Hthis = this
        await axios.get(`${host}reviews/`,   {
            params: {
                search: search,
                itemCount: count,
                page: 1
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            Hthis.list = []
            res.data.data.forEach((item: ReviewsInterface, index: number) => {
                Hthis.list.push(item)
            })
        }).catch(function (error) { 
            console.log(error)
        }) 
    }
}

export class OrderItem implements OrderItemInterface{
    pk: number
    title: string
    cost: number
    desc: number = 0
    course: {
        pk: number
        title: string
        image: string
        count_modules: number
        descPercent: number
    }

    constructor(item: OrderItemInterface){
        this.pk = item.pk
        this.title = item.title
        this.cost = item.cost
        this.course = item.course
    }

}
export class Order{
    list: OrderItem[]
    discount_course: {
        pk: number,
        module_count: number,
        in_order: number
    }[] = []

    constructor(){
        this.list = []
    }

    async getOrder(module: Number[]){
        const Hthis = this
        await axios.post(`${host}get-order/`, {
            module: module
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            Hthis.list = []
            Hthis.discount_course = []

            res.data.forEach((item: OrderItemInterface, index: number) => {
                Hthis.list.push(new OrderItem(item))

                let flag = true
                Hthis.discount_course.forEach((item_2, index_2) => {
                    if(item_2.pk == item.course.pk){
                        Hthis.discount_course[index_2].in_order += 1
                        flag = false
                    }
                })

                if(flag){
                    Hthis.discount_course.push({
                        pk: item.course.pk,
                        module_count: item.course.count_modules,
                        in_order: 1
                    })
                }
            })

            this.orderDiscont()
        }).catch(function (error) { 
            console.log(error)
        }) 
    }

    orderDiscont(){
        this.list.forEach((item, index) => {
            this.discount_course.forEach((item_2, index_2) => {
                if(item.course.pk == item_2.pk && item_2.in_order == item_2.module_count){
                    item.desc = item.course.descPercent
                }
            })
        })
        console.log(this.discount_course)
    }

    async createOrder(token: string, promo: string, module: Number[]){
        let data = {
            error: true,
            ylink: "",
            msg: "error"
        }

        const Hthis = this
        await axios.post(`${host}order/`, {
            module: module
        },{
            params: {
                promo: promo
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            data = {
                error: false,
                ylink: res.data.data.ylink,
                msg: "Модули успешно куплены"
            }
        }).catch(function (error) { 
            console.log(error)
            data = {
                error: true,
                ylink: "",
                msg: "Ошибка создания заказа"
            }
        }) 
        return data
    }
}
export class FavouriteList{
    courseList: Course[] = []
    moduleList: Module[] = []
    page: number = 1
    pageCount: number = 1

    constructor(){}

    async getData(page = 1, type=0, favourite: string[]){
        let data = {
            "error": false,
            "msg": ""
        }

        this.page = page
        const Hthis = this
        await axios.post(`${host}favourites/`, {
            favourite: favourite
        },{
            params: {
                itemCount: 24,
                page: page,
                type: type
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {  
            Hthis.moduleList = []
            Hthis.courseList = []

            if(type == 1){
                //модули
                res.data.data.forEach((item: ModuleInterface, index: number) => {
                    Hthis.moduleList.push(new Module(item))
                })
            }else{
                //курс
                res.data.data.forEach((item: CourseInterface, index: number) => {
                    Hthis.courseList.push(new Course(item))
                })
            }
        }).catch(function (error) { 
            console.log(error)
        }) 

        return data
    }
}
export class Module implements ModuleInterface{
    pk: number
    title: string
    cost: number
    miniDesc: string
    finalCost: number
    sort: number
    lesson_module: [{
        pk: number
    }]

    constructor(item: ModuleInterface){
        this.pk = item.pk
        this.title = item.title
        this.cost = item.cost
        this.miniDesc = item.miniDesc
        this.finalCost = item.finalCost 
        this.sort = item.sort
        this.lesson_module = item.lesson_module
    }
}
export class Course implements CourseInterface{
    pk: string
    title: string
    image: string
    imageDetail: string
    miniDesc: string
    cost: number
    finalCost: number
    duration: string
    about?: string
    type?: string
    content?: string
    suitable_course?: [{
        pk: number
        title: string
        image: string
        miniDesc: string
    }]
    learn?: {
        learn1: string
        learn2: string
        learn3: string
        learn4: string
        learn5: string
        learn6: string
    }
    teachers?: [{
        pk: number
        title: string
        image: string
        desc: string
    }]
    module_course?: Module[]
    descPercent: number = 0
    discountDesc: string = ""
    certificate_image: string = ""
    certificate_title: string = ""
    certificate_description: string = ""

    constructor(item: CourseInterface = {pk: "0", title: "", image: "", imageDetail: "", miniDesc: "", cost: 0, finalCost: 0, duration: ""}){
        this.pk = item.pk
        this.title = item.title
        this.image = item.image
        this.imageDetail = item.imageDetail
        this.miniDesc = item.miniDesc
        this.cost = item.cost
        this.finalCost = item.finalCost
        this.duration = item.duration
    }

    async getData(pk: string){
        let showError = false
        this.pk = `${pk}`
        const Hthis = this
        await axios.get(`${host}courses/${this.pk}/`,   {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            console.log(res.data.course)
            //Hthis.parameters = res.data
            Hthis.pk = res.data.course.pk
            Hthis.title = res.data.course.title
            Hthis.image = res.data.course.image
            Hthis.imageDetail = res.data.course.imageDetail
            Hthis.miniDesc = res.data.course.miniDesc
            Hthis.cost = res.data.course.cost
            Hthis.finalCost = res.data.course.finalCost
            Hthis.duration = res.data.course.duration
            Hthis.type = res.data.course.type
            Hthis.content = res.data.course.content
            Hthis.suitable_course = res.data.course.suitable_course
            Hthis.about = res.data.course.about
            Hthis.learn = JSON.parse(res.data.course.learn)
            Hthis.teachers = res.data.course.teachers
            Hthis.descPercent = res.data.course.descPercent
            Hthis.discountDesc = res.data.course.discountDesc

            Hthis.certificate_image = res.data.course.certificate_image
            Hthis.certificate_title = res.data.course.certificate_title
            Hthis.certificate_description = res.data.course.certificate_description

            Hthis.module_course = []
            res.data.course.module_course.forEach((item: ModuleInterface, index: number) => {
                Hthis.module_course?.push(new Module(item))
            })
            console.log(Hthis.module_course)
        }).catch(function (error) { 
            showError = true
        }) 
        return showError
    }
}
export class CoursesList{
    list: Course[]
    itemCount: number
    page: number = 1
    pageCount: number = 1

    constructor(itemCount = 5){
        this.list = []
        this.itemCount = itemCount
    }

    async getData(page = 1, search = ""){
        this.page = page
        const Hthis = this
        await axios.get(`${host}courses/`,   {
            params: {
                search: search,
                itemCount: this.itemCount,
                page: page
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: CourseInterface, index: number) => {
                Hthis.list.push(new Course({...item}))
            })
        }).catch(function (error) { 
            console.log(error)
        }) 
    } 
}
export class User{
    userToken: string = ""

    sertEmail: string = ""
    email: string = ""
    
    name: string = ""
    surname: string = ""
    minName: string = ""
    phone: string = ""
    module: Number[]

    favoriteModule: string[] = []
    favoriteCourses: string[] = []

    cart: Number[] = []
    
    constructor(){
        this.module = []

        if(localStorage.getItem("userLogin_LNOW")){
            const userProps = JSON.parse(localStorage.getItem("userLogin_LNOW") || "")
            this.userToken = userProps.userToken
            this.sertEmail = userProps.sertEmail
            this.email = userProps.email
            this.name = userProps.name
            this.surname = userProps.surname
            this.minName = userProps.minName
            this.phone = userProps.phone
        }

        if(localStorage.getItem("userFavorite_LNOW")){
            const userProps = JSON.parse(localStorage.getItem("userFavorite_LNOW") || "")
            this.favoriteModule = userProps.favoriteModule
            this.favoriteCourses = userProps.favoriteCourses
        }

        if(localStorage.getItem("userCart_LNOW")){
            const userProps = JSON.parse(localStorage.getItem("userCart_LNOW") || "")
            this.cart = userProps.cart
        }
    }
 
    async setCart(){
        localStorage.setItem("userCart_LNOW", JSON.stringify({ 
            cart: this.cart,
        }))
    }

    async setFavorite(){
        localStorage.setItem("userFavorite_LNOW", JSON.stringify({ 
            favoriteModule: this.favoriteModule,
            favoriteCourses: this.favoriteCourses
        }))
    }

    async updateData(form: {
        name: string, 
        surname: string, 
        minName: string, 
        sertEmail: string,
        phone: string, 
        password: string, 
        passwordConf: string
    }){
        let data = {
            error: true,
            msg: "text"
        }
        
        if(form.password != ""){
            if(form.password != form.passwordConf){
                return {
                    "error": true,
                    "msg": "Пароли должны совпадать"
                }
            }

            if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(form.password)){
                return {
                    "error": true,
                    "msg": "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
                }
            }            
        }

        const Hthis = this
        await axios.put(`${host}user/0/`, {
            ...form
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.userToken}`
            }
        }).then(res => { 
            console.log(res.data)
            data = {
                error: res.data.error,
                msg: res.data.msg
            }

            Hthis.name = form.name
            Hthis.surname = form.surname
            Hthis.minName = form.minName
            Hthis.phone = form.phone
            Hthis.sertEmail = form.sertEmail

            localStorage.setItem("userLogin_LNOW", JSON.stringify({ 
                userToken: Hthis.userToken,
                sertEmail: Hthis.sertEmail,
                email: Hthis.email,
                name: Hthis.name,
                surname: Hthis.surname,
                minName: Hthis.minName,
                phone: Hthis.phone,
            }))
        }).catch(function (error) { 
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        }) 
        return data
    }

    async LoadData(){
        let data = {
            error: true,
            msg: "error"
        }
        const Hthis = this
        await axios.get(`${host}user/0/`,   {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.userToken}`
            }
        }).then(res => { 
            Hthis.module = res.data.data.module
            data = {
                error: false,
                msg: ""
            }
        }).catch(function (error) { 
            console.log(error)
            data = {
                "error": true,
                "msg": "Ошибка загрузки данных"
            }
        }) 
        console.log(this.module)
        return data
    }

    async Logout(){
        localStorage.setItem("userLogin_LNOW", JSON.stringify({
            "userToken": "",
            "email": "",
            "name": "",
            "surname": "",
            "minName": "",
            "phone": ""
        }))
        this.userToken = ""
    }

    async SendPassword(email: string){
        let data = {
            error: true,
            msg: "error"
        }

        await axios.post(`${host}send-password/`, {},{
            params: {
                email: email
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            console.log("test")
            data = {
                error: res.data.error,
                msg: res.data.msg
            }
        }).catch(function (error) { 
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        }) 
        return data
    }

    async Registration(email: string, name: string, surname: string, phone: string, password: string, passwordConf: string){
        let data = {
            error: true,
            msg: "error"
        }

        if(password != passwordConf){
            return {
                "error": true,
                "msg": "Пароли должны совпадать"
            }
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(password)){
            return {
                "error": true,
                "msg": "Ваш пароль должен содержать не менее восьми (8) латинских символов в том числе: по крайней мере (1) буква в верхнем и (1) буква в нижнем регистрах."
            }
        }

        await axios.post(`${host}user/`, {
            email: email, 
            name: name, 
            surname: surname, 
            minName: "", 
            phone: phone, 
            password: password, 
            passwordConf: passwordConf
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            data = {
                error: res.data.error,
                msg: res.data.msg
            }
        }).catch(function (error) { 
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        }) 
        return data

    }

    async Login(email: string, password: string){
        let data = {
            error: true,
            msg: "error"
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(password))
        return {
            "error": true,
            "msg": "Пароль указан неверно"
        }
        
        const Hthis = this
        await axios.post(`${host}login/`, {
            email: email,
            password: password,
        }).then(res => { 
            if(res.data.error == false){
                Hthis.userToken = res.data.data.userToken
                Hthis.sertEmail = res.data.data.sertEmail
                Hthis.email = res.data.data.email
                Hthis.name = res.data.data.name
                Hthis.surname = res.data.data.surname
                Hthis.minName = res.data.data.minName
                Hthis.phone = res.data.data.phone

                localStorage.setItem("userLogin_LNOW", JSON.stringify({ 
                    userToken: res.data.data.userToken,
                    sertEmail: res.data.data.sertEmail,
                    email: res.data.data.email,
                    name: res.data.data.name,
                    surname: res.data.data.surname,
                    minName: res.data.data.minName,
                    phone: res.data.data.phone,
                }))
                data = {
                    "error": false,
                    "msg": "Вы успешно вошли"
                }
            }else{
                data = {
                    "error": true,
                    "msg": res.data.msg
                }        
            }
        }).catch(function (error) { 
            console.log(error)
            data = {
                "error": true,
                "msg": "error"
            }
        }) 
        
        return data

    }
}