import { Link } from "react-router-dom"
import arrow from '../../images/arrowback.svg'
import coursebanner from '../../images/item_courses.png'
import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import { Order, hostImg } from "../../classes"
import { PopupInfo } from "../../component/PopupInfo"
import { Helmet } from "react-helmet"

export const OrderPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupText, setOpenPopupText] = useState("false")
    const [payType, setPayType] = useState("cloudpay")
    const [promo, setPromo] = useState("")
    const [itemCount, setItemCount] = useState(0)
    const [load, setLoad] = useState(true)
    const [order, setOrder] = useState(new Order)
    
    const ClearItem = (pk: number) => {
        const indexItem = auth.user.cart.indexOf(pk)
        if (indexItem !== -1) {
            auth.user.cart.splice(indexItem, 1)
        }
        setItemCount(auth.user.cart.length)
        auth.user.setCart()
    }

    const GetData = () => {
        setLoad(true)
        order.getOrder(auth.user.cart).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })

    }

    const CreateOrder = () => {
        setLoad(true)
        order.createOrder(auth.user.userToken, promo, auth.user.cart).then(function(response){
            if(response.ylink != ""){
                auth.user.cart = []
                order.list = []
                auth.user.setCart()
                setItemCount(0)
                window.location.replace(response.ylink);
            }else{
                auth.user.LoadData().then(function(resp){
                    auth.user.cart = []
                    order.list = []
                    setItemCount(0)
                    setLoad(false)
                    setOpenPopupText(response.msg)
                    setOpenPopup(true)
                }).catch(function(error){
                    alert("error")
                })
            }
        }).catch(function(error){
            setOpenPopupText("Ошибка создания заказа")
            setOpenPopup(true)
        })
    }

    useEffect(()=>{
        if(auth.user.userToken == ""){
            window.location.replace('/login/?cart=true');
        }
        
        //Просмотра покупок на этот аккаунт 
        auth.user.LoadData().then(function(response){
            auth.user.module.forEach((item, index) => {
                const indexItem = auth.user.cart.indexOf(item)
                if (indexItem !== -1) {
                    auth.user.cart.splice(indexItem, 1)
                }
            })
            auth.user.setCart()
            setItemCount(auth.user.cart.length)

            //Получаем заказ
            GetData()
        }).catch(function(error){
            alert("error")
        })
    },[])
    
    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <Helmet>
                <title>Заказ - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="order">
                <div className="content">
                    <h2 className="block-title">Корзина</h2>
                    <div className="row">
                        <div className="col-lg-9 order-2 order-lg-1">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Link 
                                            className="order-return right"
                                            to={"/courses/"}
                                            style={{
                                                backgroundImage: `url('${arrow}')`
                                            }}
                                        > 
                                            Вернуться<br/>к курсам
                                        </Link>
                                    </div>
                                    <div className="col-md-6 right">
                                        <button 
                                            className="light-button"
                                            onClick={()=>{
                                                order.list.forEach((item, index) => {
                                                    ClearItem(item.pk)
                                                })
                                                window.location.reload()
                                            }}
                                        >
                                            Очистить корзину
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="order-item__list">
                                {
                                    order.list.map((item, index) => {
                                        return(
                                            <div className="order-item">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="order-item__image" style={{backgroundImage: `url('${hostImg}${item.course.image}')`}}></div>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <div className="grid">
                                                            <div>
                                                                <p className="order-item__category">
                                                                    <Link to={`/courses/${item.course.pk}/`}>{item.course.title}</Link>
                                                                </p>
                                                                <h3 className="order-item__title item-title">{item.title}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="grid">
                                                            <div className="order-item__price">
                                                                <p>
                                                                    {
                                                                        item.cost == 0 ? (
                                                                            <>Бесплатно</>
                                                                        ):(
                                                                            <>
                                                                                {
                                                                                    item.desc != 0 ? (
                                                                                        <>
                                                                                            <span>{item.cost} ₽</span>
                                                                                            {(item.cost - (item.cost / 100 * item.desc))} ₽
                                                                                        </>
                                                                                    ):(
                                                                                        <>
                                                                                            {item.cost} ₽
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </p>
                                                                <div className="right">
                                                                    <button 
                                                                        onClick={()=>{
                                                                            ClearItem(item.pk)
                                                                            // GetData()
                                                                            window.location.reload()
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="order-promo__block">
                                <h2 className="block-title">Промокод</h2>
                                <input 
                                    type="text" 
                                    placeholder="Введите промокод" 
                                    value={promo}
                                    className="base-input"
                                    onChange={(e)=>{setPromo(e.target.value)}}
                                    style={{
                                        borderTop: "1px solid #E8E8E8"
                                    }}
                                /> 

                                {
                                    order.list.length ? (
                                        <button 
                                            className="orange-button"
                                            onClick={()=>{
                                                CreateOrder()
                                            }}
                                        >
                                            Начать обучение
                                        </button>
                                    ):""
                                }
                            </div>

                        </div>
                        <div className="col-lg-3 order-1 order-lg-2">
                            <div className="order__amount-information">
                                <h2 className="item-title center">В корзине</h2>
                                <p className="mini-paragraf center">{itemCount} элемент(а)</p> 
                                {
                                    order.list.length ? (
                                        <button
                                            className="orange-button"
                                            onClick={()=>{
                                                CreateOrder()
                                            }}
                                        >
                                            Начать обучение
                                        </button>
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                openPopup ? (
                    <PopupInfo text={openPopupText} close={setOpenPopup} />
                ):""
            }
        </>
    )
}