import axios from "axios"
import { host } from "."

export interface PurchCourseInterface{
    pk: number
    title: string
    image: string
    miniDesc: string 
    modules?: PurchModule[]
    formLink?: string
    date?: string
    email?: string
}
export interface PurchModulesInterface{
    course: number
    pk: number
    sort: number
    title: string 
    miniDesc: string
    lesson_count?: number
    completed?: number[]
}

export interface PurchLessonInterface{
    course: number
    module: number
    pk: number
    title: string
    content: string
    miniDesc: string
    test?: [
        {
            questionText: string
            answer: [{
                question: string|undefined
                answer: boolean|string
                text: string|undefined
            }]
        }
    ]
}

//------------------------------------------------------//



export class PurchLesson implements PurchLessonInterface{
    course: number
    miniDesc: string = ""
    module: number
    pk: number
    title: string
    content: string
    test?: [
        {
            questionText: string
            answer: [{
                question: string|undefined
                answer: boolean|string
                text: string|undefined
            }]
        }
    ]

    constructor(item: PurchLessonInterface){
        this.course = item.course
        this.module = item.module
        this.pk = item.pk
        this.title = item.title
        this.content = item.content
        this.test = item.test
        this.miniDesc = item.miniDesc
    }

    async completeLesson(token: string){
        const Hthis = this
        let data = ""
        await axios.get(`${host}purchased/lesson/complete/`,   {
            params: {
                course: this.course,
                module: this.module,
                lesson: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            data = res.data.msg
        }).catch(function (error) { 
            console.log(error)
        }) 

        return data
    }

    async saveTesting(token: string, testing: string, score: string){
        await axios.post(`${host}purchased/testing/complete/`, {
            testing: testing,
            score: score
        }, {
            params: {
                course: this.course,
                module: this.module,
                lesson: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            console.log(res.data)
        }).catch(function (error) { 
            console.log(error)
        }) 
    }

    async loadTesting(token: string){
        let data = [
            {
                pk: 0,
                createDate: "12.12.2002",
                score: "22"
            }
        ]
        await axios.get(`${host}purchased/lesson/testing/`,   {
            params: {
                course: this.course,
                module: this.module,
                lesson: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            data = res.data
        }).catch(function (error) { 
            data = []
            console.log(error)
        }) 
        return data
    }

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}purchased/lesson/`,   {
            params: {
                course: this.course,
                module: this.module,
                lesson: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            Hthis.title = res.data.title
            Hthis.content = res.data.content
            Hthis.test =  JSON.parse(res.data.test)
        }).catch(function (error) { 
            console.log(error)
        }) 
    } 

}
export class PurchModule implements PurchModulesInterface{
    course: number = 0
    pk: number
    sort: number
    title: string 
    miniDesc: string 
    lesson_count?: number = 0
    completed?: number[] = []
    lessons?: PurchLesson[]

    constructor(data: PurchModulesInterface){
        this.course = data.course
        this.pk = data.pk
        this.sort = data.sort
        this.title = data.title 
        this.miniDesc = data.miniDesc
        this.lesson_count = data.lesson_count
        this.completed = data.completed
    }


    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}purchased/module/`,   {
            params: {
                pk: this.course,
                mod: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            console.log(res.data)
            Hthis.completed =  JSON.parse(res.data.completed)
            Hthis.title = res.data.title
            Hthis.lessons = []
            res.data.lessons.forEach((item: PurchLessonInterface, index: number) => {
                Hthis.lessons?.push(new PurchLesson(item))
            })
        }).catch(function (error) { 
            console.log(error)
        }) 
    } 

}
export class PurchCourse implements PurchCourseInterface{
    pk: number
    title: string
    image: string
    miniDesc: string
    modules?: PurchModule[]
    formLink?: string
    date?: string
    email?: string

    constructor(item: PurchCourseInterface){
        this.pk = item.pk
        this.title = item.title
        this.image = item.image
        this.miniDesc = item.miniDesc
    }

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}purchased/course/`,   {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => { 
            console.log(res.data)
            Hthis.title = res.data.course.title
            Hthis.miniDesc = res.data.course.desc
            Hthis.modules = []
            Hthis.formLink = res.data.course.formLink
            Hthis.date = res.data.course.date

            Hthis.email = res.data.email

            if(Hthis.date != ""){
                let h_date = Hthis.date?.split("T")
                Hthis.date = `${h_date![0]} ${h_date![1].split(".")[0]}`
            }

            res.data.modules.forEach((item: any, index: number) => {
                Hthis.modules?.push({
                    ...item.module,
                    completed: JSON.parse(item.completed),
                    lesson_count: item.module.lesson_module.length
                })
            })

            console.log()
        }).catch(function (error) { 
            console.log(error)
        }) 
    } 
}
export class PurchList{
    modules: PurchModule[]
    course: PurchCourse[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.course = []
        this.modules = []
    }

    async getData(page = 1, type=0, token: string){
        this.page = page
        const Hthis = this
        await axios.get(`${host}purchased/`,   {
            params: {
                itemCount: 20,
                page: page,
                type: type
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(res => {  
            console.log(res.data.data)
            Hthis.modules = []
            Hthis.course = []
            if(type == 1){ 
                res.data.data.forEach((item: any, index: number) => {
                    Hthis.modules.push(new PurchModule(
                        {
                            ...item.module,
                            lesson_count: item.module.lesson_module.length
                        }
                    ))
                })
            }else{
                res.data.data.forEach((item: PurchCourseInterface, index: number) => {
                    Hthis.course.push(new PurchCourse({...item}))
                })
            }
            console.log(Hthis.modules)
            Hthis.pageCount = res.data.pageCount
        }).catch(function (error) { 
            console.log(error)
        }) 
    } 
}


